<script setup lang="ts">
import CheckCircleIcon from "@/assets/icons/check-circle-solid.svg?component"
import ErrorCircleIcon from "@/assets/icons/error-circle-solid.svg?component"
import InfoCircleIcon from "@/assets/icons/info-circle-solid.svg?component"
import WarningCircleIcon from "@/assets/icons/warning-circle-solid.svg?component"

// this type isn't exported by notiwind, so define it here for now
// https://github.com/emmanuelsw/notiwind#typescript
// https://github.com/emmanuelsw/notiwind/blob/main/src/types.ts
type Notification = {
  id: number
  group: string
  title?: string
  text: string
  type: "info" | "success" | "warn" | "error"
}

interface Props {
  notification: Notification
  close(id: number): void
}

const props = withDefaults(defineProps<Props>(), {})

const backgroundClasses = computed(() => {
  const type = props.notification?.type
  if (type === "success") return "bg-emerald-50 border-emerald-500"
  if (type === "warn") return "bg-amber-50 border-amber-500"
  if (type === "error") return "bg-red-50 border-red-500"
  return "bg-purple-50 border-purple-500" // info
})

const closeButtonClasses = computed(() => {
  const type = props.notification?.type
  if (type === "success") return "focus:ring-emerald-500"
  if (type === "warn") return "focus:ring-red-500"
  if (type === "error") return "focus:ring-emerald-500"
  return "focus:ring-indigo-500" // info
})

const textColorClass = computed(() => {
  const type = props.notification?.type
  if (type === "success") return "text-emerald-700"
  if (type === "warn") return "text-amber-700"
  if (type === "error") return "text-red-700"
  return "text-purple-700" // info
})

const titleColorClass = computed(() => {
  const type = props.notification?.type
  if (type === "success") return "text-emerald-900"
  if (type === "warn") return "text-amber-900"
  if (type === "error") return "text-red-900"
  return "text-purple-900" // info
})

function isType(type: string): boolean {
  return props.notification?.type === type
}
</script>

<template>
  <div
    :class="backgroundClasses"
    class="pointer-events-auto w-full max-w-sm overflow-hidden border rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
  >
    <div class="p-4">
      <div class="flex items-center" :class="titleColorClass">
        <div class="flex-shrink-0">
          <CheckCircleIcon
            v-if="isType('success')"
            class="w-5 h-5 text-emerald-500"
            aria-hidden="true"
          />
          <WarningCircleIcon
            v-else-if="isType('warn')"
            class="w-5 h-5 text-amber-500"
            aria-hidden="true"
          />
          <ErrorCircleIcon
            v-else-if="isType('error')"
            class="w-5 h-5 text-red-500"
            aria-hidden="true"
          />
          <InfoCircleIcon v-else class="w-5 h-5" aria-hidden="true" />
        </div>

        <div class="ml-3 w-0 flex-1 pt-0.5">
          <p class="text-sm font-medium">
            {{ props.notification.title }}
          </p>
        </div>

        <div class="ml-4 flex flex-shrink-0">
          <button
            type="button"
            :class="closeButtonClasses"
            class="inline-flex rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2"
            @click="props.close(props.notification.id)"
          >
            <span class="sr-only">Close</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                d="M5.757 4.343a1 1 0 0 0-1.414 1.414L8.585 10l-4.242 4.243a1 1 0 1 0 1.414 1.414L10 11.414l4.242 4.243a1 1 0 0 0 1.414-1.414L11.414 10l4.243-4.243a1 1 0 0 0-1.415-1.414L10 8.586 5.757 4.343Z"
              />
            </svg>
          </button>
        </div>
      </div>

      <div v-if="props.notification.text" class="mt-4">
        <p class="text-sm" :class="textColorClass">
          {{ props.notification.text }}
        </p>
      </div>
    </div>
  </div>
</template>
