<script setup>
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue"
import { Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline"
import MainNavigationItem from "@/components/MainNavigationItem.vue"
import UserAvatar from "@/components/UserAvatar.vue"
import { useAuth } from "@/composables/auth"

const { user, logout } = useAuth()

// used in dev mode with fixed API token
const placeholderUser = {
  name: "Streamfold User",
  email: "support@streamfold.com",
  profileImage: "/images/avatars/streamfold.png",
}

const navigation = [
  { name: "Streams", href: "/streams", pathRoot: "streams" },
  { name: "Sources", href: "/sources", pathRoot: "sources" },
  { name: "Destinations", href: "/destinations", pathRoot: "destinations" },
]
const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Settings", href: "/account/" },
  { name: "Sign out", href: "#" },
]

const activeUser = computed(() => {
  if (user) return user

  // no userfront login, use placeholder in development with fixed API token
  const deployEnv = useRuntimeConfig().public.deploymentEnvironment
  if (deployEnv === "development") return placeholderUser

  return undefined // not logged in
})

const currentPathRoot = computed(() => {
  const path = useRoute().path
  if (!path) return ""
  return path.split("/")[1]
})

const isLoggedIn = computed(() => !!user)
</script>

<template>
  <Disclosure v-slot="{ open }" as="nav" class="bg-indigo-600 md:px-8">
    <div
      class="max-w-9xl mx-auto flex h-16 items-center justify-between px-4 md:px-0"
    >
      <div class="flex items-center">
        <div class="flex-shrink-0 flex items-center">
          <NuxtLink to="/streams" class="flex items-center">
            <img class="h-7" src="/images/streamfold-logo.svg" alt="" />
            <img
              class="h-[1.125rem] ml-2"
              src="/images/streamfold-white.svg"
              alt="Streamfold"
            />
          </NuxtLink>
        </div>
        <div class="hidden md:block">
          <div class="ml-10 flex items-baseline space-x-4">
            <MainNavigationItem
              v-for="item in navigation"
              :key="item.name"
              :to="item.href"
              :path-root="item.pathRoot"
            >
              {{ item.name }}
            </MainNavigationItem>
          </div>
        </div>
      </div>
      <div class="hidden md:block">
        <div class="ml-4 flex items-center md:ml-6">
          <!-- Profile dropdown -->
          <NuxtLink to="https://docs.streamfold.com/" class="flex items-center">
            <VButton color="header">Documentation</VButton>
          </NuxtLink>
          <Menu as="div" class="relative ml-3">
            <div>
              <MenuButton
                class="flex max-w-xs items-center rounded-md bg-transparent text-sm focus:outline-none focus:ring-0"
              >
                <span class="sr-only">Open user menu</span>
                <UserAvatar :user="activeUser" />
              </MenuButton>
            </div>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                v-show="isLoggedIn"
                class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <MenuItem v-slot="{ active }">
                  <a
                    href=""
                    :class="[active ? 'bg-slate-100' : '']"
                    class="block px-4 py-2 text-sm text-slate-700"
                    @click.prevent="logout"
                  >
                    Sign out
                  </a>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
      <div class="flex md:hidden">
        <!-- Mobile menu button -->
        <DisclosureButton
          class="inline-flex items-center justify-center rounded-md bg-indigo-700 p-2 text-white"
        >
          <span class="sr-only">Open main menu</span>
          <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
          <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
        </DisclosureButton>
      </div>
    </div>

    <DisclosurePanel v-slot="{ close }" class="md:hidden">
      <!-- Mobile nav menu -->
      <div class="space-y-1 px-4 pt-2 pb-3 border-t border-indigo-700">
        <div v-for="item in navigation" :key="item.name" @click="close">
          <NuxtLink
            :to="item.href"
            :class="[
              currentPathRoot === item.pathRoot
                ? 'bg-indigo-700 text-white'
                : 'text-indigo-300 hover:bg-indigo-700 hover:text-white hover:bg-opacity-50',
              ,
            ]"
            class="block px-3 py-2 rounded-md text-base font-medium"
          >
            {{ item.name }}
          </NuxtLink>
        </div>
      </div>
      <div class="border-t border-indigo-700 pt-4 pb-4">
        <div class="flex items-center px-5">
          <div class="flex-shrink-0">
            <UserAvatar :user="activeUser" size="large" />
          </div>
          <div class="ml-3 flex-1">
            <div class="text-sm font-medium text-white">
              {{ activeUser.name }}
            </div>
            <div class="text-sm font-medium leading-none text-indigo-400">
              {{ activeUser.email }}
            </div>
          </div>
          <a v-show="isLoggedIn" href="" @click.prevent="logout">
            <span class="sr-only">Sign out</span>
            <VButton full-width class="bg-indigo-700">Sign out</VButton>
          </a>
        </div>
        <div v-show="false" class="mt-3 space-y-1 px-2">
          <DisclosureButton
            v-for="item in userNavigation"
            :key="item.name"
            as="a"
            :href="item.href"
            class="block rounded-md px-3 py-2 text-base font-medium text-slate-400 hover:bg-slate-700 hover:text-white"
          >
            {{ item.name }}
          </DisclosureButton>
        </div>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>
