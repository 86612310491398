<script setup lang="ts">
import { UserIcon } from "@heroicons/vue/20/solid"

import type { AppUser } from "@/types/types"

interface Props {
  user: AppUser
  size?: "small" | "medium" | "large"
}

const props = withDefaults(defineProps<Props>(), {
  size: "medium",
})

const sizeClasses: { [K in NonNullable<Props["size"]>]: string } = {
  small: "h-6 w-6 text-xxs rounded-md",
  medium: "h-8 w-8 text-xxs rounded-md",
  large: "h-10 w-10 text-xs rounded-md",
}

const classes = computed(() => sizeClasses[props.size])
const userImagePath = computed(() => props.user?.profileImage)
const userInitials = computed(() => initials(props.user?.name))

// extract initials from a user's name
function initials(name: string | undefined) {
  if (!name || !name.trim()) return ""

  const names = name.split(" ").map((n) => n.trim())
  let initials = names[0].charAt(0)
  // get two letters if we can
  if (names.length > 1) {
    initials += names[1].charAt(0)
  }
  return initials
}
</script>

<template>
  <img v-if="userImagePath" :class="classes" :src="userImagePath" alt="" />
  <div
    v-else
    :class="classes"
    class="flex text-white bg-indigo-500 font-bold items-center justify-center"
  >
    <span v-if="userInitials">{{ userInitials }}</span>
    <UserIcon v-else class="block h-5 w-5" />
  </div>
</template>
