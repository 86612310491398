<script setup lang="ts">
import { computed } from "vue"
import type { RouteLocationRaw } from "vue-router"
import { useRoute } from "#imports"

const props = defineProps<{
  pathRoot: string
  to: string | RouteLocationRaw
}>()

const current = computed(() => props.pathRoot === currentPathRoot.value)

const currentPathRoot = computed(() => {
  const path = useRoute().path
  if (!path) return ""
  return path.split("/")[1]
})
</script>

<template>
  <NuxtLink
    :to="to"
    :class="[
      current
        ? 'bg-indigo-700 text-white'
        : 'text-indigo-300 hover:bg-indigo-700 hover:bg-opacity-50 hover:text-white',
      'px-3 py-2 rounded-md text-sm font-medium',
    ]"
    :aria-current="current ? 'page' : undefined"
  >
    <slot />
  </NuxtLink>
</template>
